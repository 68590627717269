import { LOCATION_CHANGE, replace } from 'redux-first-history';
import { getContext, put, select, takeEvery } from 'redux-saga/effects';
import { OAuth2CodeWrongOrExpiredError } from 'src/features/oauth2';
import { trackError } from 'src/lib/appError';
import type { OAuth2Service } from 'src/services/OAuth2Service';
import type { RootState } from 'src/store';
import { setAccessToken, setAuthError, setIdToken, setRefreshToken } from 'src/store/actions/auth';

// From: https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.2.1
enum RFC6749ResponseError {
    InvalidRequest = 'invalid_request',
    UnauthorizedClient = 'unauthorized_client',
    AccessDenied = 'access_denied',
    UnsupportedResponseType = 'unsupported_response_type',
    InvalidScope = 'invalid_scope',
    ServerError = 'server_error',
    TemporarilyUnavailable = 'temporarily_unavailable',
}

const RFC6749ResponseErrorMessages: Record<RFC6749ResponseError, string> = {
    [RFC6749ResponseError.InvalidRequest]: 'There was a communication error. (InvalidRequest)',
    [RFC6749ResponseError.UnauthorizedClient]: 'The credentials you have entered are invalid.',
    [RFC6749ResponseError.AccessDenied]: 'The credentials you have entered are invalid.',
    [RFC6749ResponseError.UnsupportedResponseType]: 'There was a communication error. (UnsupportedResponseType)',
    [RFC6749ResponseError.InvalidScope]: 'There was a communication error. (InvalidScope)',
    [RFC6749ResponseError.ServerError]: 'There was a communication error. (ServerError)',
    [RFC6749ResponseError.TemporarilyUnavailable]: 'Login is currently unavailable. Please try again later.',
};

export function* listenForCallback() {
    if (APP_ELECTRON) {
        yield takeEvery(LOCATION_CHANGE, handleCallback);
    } else {
        yield* handleCallback();
    }
}

export function* handleCallback() {
    const location: RootState['router']['location'] = yield select((state: RootState) => state.router.location);

    if (location?.pathname !== '/oauth2/callback') return;

    const query = new URLSearchParams(location.search);

    const error = query.get('error') as RFC6749ResponseError | null;
    if (error) {
        const error_reason = query.get('error_reason');
        const error_description = query.get('error_description');

        // Always track the error.
        trackError(new Error(`RFC6749ResponseError: ${error}`), {
            extra: {
                error: error,
                error_reason: error_reason ?? '',
                error_description: error_description ?? '',
            },
        });

        // If we have a message for the error, show that to the user.
        if (Object.values(RFC6749ResponseError).includes(error)) {
            yield put(setAuthError(RFC6749ResponseErrorMessages[error]));
        }

        return;
    }

    const code = query.get('code');
    if (!code) {
        trackError(new Error('No code during authentication flow'), { extra: query });
        yield put(setAuthError('No code detected.'));
        return;
    }

    try {
        const oauth2: OAuth2Service = yield getContext('oAuth2Client');
        const { state, accessToken, idToken, refreshToken }: Awaited<ReturnType<OAuth2Service['exchangeCode']>> =
            yield oauth2.exchangeCode(location.search);
        yield put(setAccessToken(accessToken));
        yield put(setIdToken(idToken));
        if (refreshToken) yield put(setRefreshToken(refreshToken));

        // Determine where to redirect the user
        const defaultRedirect = '/dashboard';
        const blockedPaths = ['/logout', '/loggedout', '/login'];

        // Only use returnTo from state if it exists and is not a blocked path
        const returnTo = state.returnTo && !blockedPaths.includes(state.returnTo) ? state.returnTo : defaultRedirect;

        yield put(replace(returnTo));
    } catch (error: unknown) {
        if (error instanceof Error) {
            trackError(error, { extra: { error: error } });

            yield put(replace(location.pathname));

            if (error instanceof OAuth2CodeWrongOrExpiredError) {
                yield put(setAuthError(RFC6749ResponseErrorMessages[RFC6749ResponseError.InvalidRequest]));
            } else {
                yield put(setAuthError(error.message));
            }
        } else {
            yield put(setAuthError('An unknown authentication error has occurred.'));
        }
    }
}
