import type { SlugMapService } from '.';
import { effect, signal } from '@preact/signals-react';
import { injectable, inject } from 'src/features/ioc';
import type { SlugMap } from 'src/lib/dictionary/dictionary';
import { QueryObserverModel } from 'src/models/QueryObserverModel';
import { getWholeSlugMapQuery } from 'src/queries/slugMap';
import { ReactBindings } from 'src/types/bindings';

@injectable()
export class SlugMapServiceImpl implements SlugMapService {
    constructor(
        @inject('QueryObserverModelFactory') queryObserverModelFactory: ReactBindings['QueryObserverModelFactory'],
    ) {
        this.#query = queryObserverModelFactory(getWholeSlugMapQuery());
        this.#query.subscribe((result) => {
            if (result.isSuccess) {
                this.slugMap.value = result.data;
            }
        });
    }

    async initialize(): Promise<void> {
        if (this.slugMap.value) {
            return;
        }

        using stack = new DisposableStack();

        await new Promise<void>((resolve) => {
            stack.defer(
                effect(() => {
                    if (this.slugMap.value) {
                        resolve();
                    }
                }),
            );
        });
    }

    #query: QueryObserverModel<SlugMap>;

    slugMap = signal<SlugMap>(null as any);
}
