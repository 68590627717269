import type { QueryObserverOptions } from '@tanstack/react-query';
import type { Universe } from '@thinkalpha/platform-ws-client/contracts/universe.js';
import { getUniverses, getDefaultUniverse, getUniverseById } from 'src/lib/universes';

// Constants
export const UNIVERSES = 'universes';
export const ALL = 'all';
export const DEFAULT = 'default';
/**
 * Generates a query to get a universe by an id
 *
 * @param id string id of the universe, or null
 * @returns an object with queryKey, queryFn, and enabled
 */
export function getUniverseByIdQuery(id: string): QueryObserverOptions<Universe | undefined> {
    return {
        queryKey: [UNIVERSES, id],
        queryFn: async () => await getUniverseById(id),
    };
}

/**
 * Get universes for a user.
 *
 * @returns an object with queryKey and queryFn
 */
export function getUniversesQuery(): QueryObserverOptions<Universe[]> {
    return {
        queryKey: [UNIVERSES, ALL],
        queryFn: () => getUniverses(),
    };
}

/**
 * Generates a query to get the default universe
 *
 * @param id string id of the universe, or null
 * @returns an object with queryKey and queryFn
 */
export function getDefaultUniverseQuery(): QueryObserverOptions<Universe | undefined> {
    return {
        queryKey: [UNIVERSES, DEFAULT],
        queryFn: () => getDefaultUniverse(),
    };
}
