import type {
    BinaryOperator,
    ComparisonOperator,
    ConcreteDataType,
    ConcreteUnit,
    KnownAstNode,
    ParserResult,
} from '@thinkalpha/language-services';
import {
    BooleanComparisonOperator,
    canConvert,
    getNameForDataType,
    LogicalOperator,
    NumericComparisonOperator,
    parser,
    render,
    replaceNodes,
    StringComparisonOperator,
} from '@thinkalpha/language-services';
import type { AnalyzerFunction } from 'src/components/deprecated/filter-editor/model';
import { fakeConstStringParserResult } from 'src/lib/parser';

/**
 * Given a formula string, maps over each node in the formula string with a
 * replacer function, then returns the new formula string.
 *
 * @param formula a formula string
 * @param analyzers array of AnalyzerFunction[]; the analyzers provided will be
 * run on the parsed formula before the replacer is run, providing relevant nodes
 * with a functionDef property that is accessible in the replacer
 * @param replacer the replacer function will map over each node in the formula
 *
 * @returns a new formula string
 */
export function replaceFormulaNodes(
    formula: string,
    analyzers: AnalyzerFunction[],
    replacer: (node: KnownAstNode | null, parent: KnownAstNode | null) => KnownAstNode | null,
): string {
    const { root } = parse(formula, analyzers);

    // If no root exists, return original formula unchanged
    if (!root) return formula;

    const newRoot = replaceNodes(root, replacer);

    return render(newRoot);
}

interface EnhancedTag {
    label: string;
    dataType: ConcreteDataType;
    unit: ConcreteUnit | null;
}

export type TagMap = Map<string, EnhancedTag>;

export function removeWrappingParentheses(string: string) {
    if (string[0] === '(' && string[string.length - 1] === ')') {
        return string.slice(1, -1);
    }

    return string;
}

/**
 * @deprecated Use FormulaService.parse instead
 */
export function parse(
    value: string,
    analyzers: AnalyzerFunction[],
    equalsMode = false,
    dataTypeRequired?: ConcreteDataType,
): ParserResult {
    if (equalsMode && !value.startsWith('=')) {
        return fakeConstStringParserResult(value);
    }
    const filterValue = equalsMode ? value.substr(1) : value;

    let parserResult = parser(filterValue ?? '', {});
    if (parserResult.root) {
        for (const analyzer of analyzers) analyzer(parserResult.root);
    }

    if (dataTypeRequired) {
        if (
            !parserResult.root ||
            !parserResult.root.dataType ||
            !canConvert(parserResult.root.dataType, dataTypeRequired)
        ) {
            const newError = {
                text:
                    `Expected type ${getNameForDataType(dataTypeRequired)}` +
                    (parserResult.root && parserResult.root.dataType
                        ? `, but instead found ${getNameForDataType(parserResult.root.dataType)}`
                        : ''),
                range: { start: 0, end: parserResult.text.length },
                source: 'if-then',
            };
            parserResult = { ...parserResult, errors: [...parserResult.errors, newError], valid: false };
        }
    }

    return parserResult;
}

export function isLogicalOperator(operator: BinaryOperator): operator is LogicalOperator {
    return operator in LogicalOperator;
}

export function isComparisonOperator(operator: BinaryOperator): operator is ComparisonOperator {
    return (
        operator in NumericComparisonOperator ||
        operator in StringComparisonOperator ||
        operator in BooleanComparisonOperator
    );
}
